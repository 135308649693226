<template>
  <div class="m_videoPlayer">
    <div class="player">
      <video-player
        v-if="playerList.length > 0"
        ref="videoPlayer"
        :focus="false"
        :ifBrush="true"
        :if-drag-seek="dragSeek"
        :brushTime="1800"
        @play="playerStart"
        @ended="playEnded"
        @switch="playerSwitch"
        @timeupdate="videoPlayerTimeUpDate"
        :playerList="playerList"
        :currentUrl="playerIndex"
      />
    </div>
    <div class="courseInfo">
      <v-clamp autoresize :max-lines="1" class="title">
        {{ courseInfo && courseInfo.courseName }}
        <template
          v-slot:after="{ expand, collapse, toggle, clamped, expanded }"
        >
          <span @click="expand" v-if="clamped" class="expand-collapse"
            ><svg-icon class="expandicon" icon="icon-rongqi" />展开</span
          >
          <span @click="collapse" v-if="expanded" class="expand-collapse"
            ><svg-icon class="expandedicon" icon="icon-rongqi" />收起</span
          >
        </template>
      </v-clamp>
      <div class="name-time">
        <div class="name">
          <svg-icon icon="icon-jiangshi" />
          <div>{{ courseInfo && courseInfo.unitSpeaker }}</div>
        </div>
        <div class="time">
          <svg-icon icon="icon-keshi" />
          <div>{{ courseInfo && courseInfo.credits }}学时</div>
        </div>
      </div>
      <div>
        <div
          class="file-list"
          v-if="courseInfo.PDFurl"
          @click="coursewareDownload(courseInfo.PDFurl)"
        >
          <svg-icon icon="icon-fujian" />
          <div>文字版课件下载</div>
        </div>
      </div>
      <van-divider />
      <div class="comment">
        <div class="satisfaction">您对课程的满意度如何？</div>
        <div class="satisfactionValue">
          <div @click="$router.go(-1)">满意度</div>
          <van-rate
            style="margin: 0 10px"
            :count="5"
            color="#F7BA2A"
            v-model="satisfactionValue"
          />
          <div style="color: #ba2926">
            {{ satisfactionLabel(satisfactionValue) }}
          </div>
        </div>
        <div class="commontInput">
          <input class="input" type="text" v-model="appraise" />
          <div class="button" @click="appraiseEvaluation">提交评价</div>
        </div>
      </div>
    </div>
    <div class="studyProgress">
      <div class="top">
        <div class="left">当前学习进度</div>
        <div class="right">
          <span>{{ studyProgress }}</span
          >/{{ playerList && playerList.length }}
        </div>
      </div>
      <div class="bottom">
        <van-progress
          v-if="!isNaN(progressBar)"
          color="#BA2926"
          :show-pivot="false"
          track-color="#D8D8D8"
          :stroke-width="6"
          :percentage="progressBar || 0"
        />
      </div>
    </div>
    <div class="courseCatalogue">
      <div class="title">
        课程目录<span class="value"
          >（ {{ playerIndex + 1 }} / {{ playerList.length }} ）</span
        >
      </div>
      <van-list :finished="finished" finished-text="没有更多了">
        <div
          class="item"
          :class="playerIndex == index ? 'itemPlayer' : ''"
          v-for="(item, index) in playerList"
          @click="playItem(item, index)"
          :key="index"
        >
          <div class="left">
            <div class="top">
              <img
                v-if="playerIndex === index"
                src="@/assets/icon/loading.gif"
                style="max-width: 20px; width: 20px; margin-right: 5px"
                alt=""
                srcset=""
              />
              <div class="index">P{{ index + 1 }}. 第{{ index + 1 }}节</div>
            </div>
            <div class="titles">
              {{ item.courseContName }}
            </div>
          </div>
          <div class="right">
            <div v-if="!item.isWanCheng" style="text-align: center">
              <van-circle
                :size="30"
                v-model="item.isWanChengRatio"
                layer-color="#D8D8D8"
                color="#5EB35E"
                :stroke-width="200"
                :rate="item.isWanChengRatio || 0"
                :speed="100"
              />
              <div style="font-size: 12px; text-align: center; color: #5eb35e">
                {{ item.isWanChengRatio || 0 }}%
              </div>
            </div>
            <svg-icon
              v-if="item.isWanCheng"
              icon="icon-wancheng"
              style="font-size: 20px"
              color="#67C23A"
            />
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import VideoPlayer from "@/components/videoPlayer.vue";
import VClamp from "vue-clamp";
import SvgIcon from "@/components/svgIcon.vue";
import {
  getCourseCurriculumEvaluation,
  getCourseGetUserCourseByIDList,
  getStudyProcessEnd,
  getStudyProcessStart,
  getStudyProcessUpdate,
} from "@/api/specializedController";
import { showModel, showToast } from "@/utils/tools";
import * as storage from "@/utils/storage";

export default {
  name: "m_videoPlayer",
  components: { SvgIcon, VideoPlayer, VClamp },

  data() {
    return {
      CurrentTime: 0,
      courseId: 0,
      studyCourseId: 0,
      loading: false,
      finished: false,
      appraise: "",
      satisfactionValue: 5,
      playerList: [],
      playerIndex: 0,
      playerEndIndex: 0,
      courseInfo: {},
      studyProcessId: 0,
      playingTime: 0,
      TimeUpDate: undefined,
      forTheFirstTime: true,
    };
  },
  destroyed() {
    //销毁
    if (this.TimeUpDate) {
      clearInterval(this.TimeUpDate);
      this.TimeUpDate = null;
    }
  },
  computed: {
    studyProgress() {
      const dataList = JSON.parse(JSON.stringify(this.playerList));
      return (
        dataList.filter((item) => {
          return item.isWanCheng === true;
        }).length || 0
      );
    },
    progressBar() {
      // 如果 studyProgress 是 0 或 undefined，则返回 0
      return (this.studyProgress ?? 1) === 0
        ? 0
        : (this.studyProgress / this.playerList.length) * 100;
    },
    dragSeek() {
      const item = this.playerList[this.playerIndex];
      return item.isWanCheng;
    },
    satisfactionLabel() {
      return (t) => {
        let result = "";
        if (t === 1) {
          result = "极差";
        }
        if (t === 2) {
          result = "失望";
        }
        if (t === 3) {
          result = "一般";
        }
        if (t === 4) {
          result = "满意";
        }
        if (t === 5) {
          result = "惊喜";
        }

        return result;
      };
    },
    yearId() {
      return storage.getStore("yearId");
    },
  },
  activated() {
    console.log("onshow播放");
  },
  created() {
    var that = this;
    that.courseId = this.$route.query.courseId;
    that.studyCourseId = this.$route.query.studyCourseId;
    that.getCourseByIDList();
  },
  methods: {
    // 附件下载
    coursewareDownload(file) {
      if (file) {
        const PDFUrl = file[0];
        const a = document.createElement("a");
        a.href = PDFUrl.url;
        a.download = PDFUrl.name;
        a.click();
      }
    },
    // 开始播放
    playerStart(args) {
      const that = this;
      if (that.forTheFirstTime) {
        this.forTheFirstTime = false;
        const item = this.playerList[this.playerIndex];
        getStudyProcessStart({
          ppXmWjbzp5b8: item.courseContId,
          c3R1ZHlDb3Vyc2VJZA: item.studyCourseId,
          trainingId: this.yearId,
        }).then((res) => {
          that.studyProcessId = res.data.studyProcessId;
          if (res.data.playingTime) {
            args.currentTime(res.data.playingTime);
          }
          if (!this.TimeUpDate) {
            this.uploadDateTime();
          }
        });
      }
    },
    // 播放结束
    playEnded(data) {
      console.log("播放结束", data);
      getStudyProcessEnd({
        c3R1ZHlQcm9jZXNzSWQ: this.studyProcessId,
        trainingId: this.yearId,
      }).then((res) => {
        this.$set(this.playerList[this.playerEndIndex], "isWanCheng", true);
        if (this.TimeUpDate) {
          clearInterval(this.TimeUpDate);
          this.TimeUpDate = null;
        }
        this.forTheFirstTime = true;
        this.playerEndIndex = JSON.parse(JSON.stringify(this.playerIndex));
        if (res.data.state === 2) {
          showModel({
            content: "恭喜您已经完成所有课程学习，请前往考试吧",
            showClose: true,
            showCancelButton: true,
            confirmButtonText: "前往考试",
            closeModal: false,
            confirm: () => {
              console.log("前往考试");
              this.keepAliveNavigation(
                "examination?courseId=" + res.data.courseId
              );
            },
          });
        }
        this.$refs.videoPlayer.playerEl.play();
      });
    },
    // 星级评价
    appraiseEvaluation() {
      const that = this;
      getCourseCurriculumEvaluation({
        courseContId: that.courseId,
        star: that.satisfactionValue,
        evaluationContent: that.appraise,
      }).then((res) => {
        if (res.data) {
          that.satisfactionValue = 5;
          that.appraise = "";
          showToast({ type: "success", message: "评价成功" });
        }
      });
    },
    // 获取课程详情
    getCourseByIDList() {
      var that = this;
      getCourseGetUserCourseByIDList({
        trainingPlanId: this.yearId,
        courseId: that.courseId,
        studyCourseId: that.studyCourseId,
      }).then((res) => {
        that.courseInfo = res.data;
        let IsKaoShi = true;
        that.playerList = res.data.couresContentList.map((item) => {
          if (item.videoUrl) {
            item.url = JSON.parse(item.videoUrl)[0].url;
            if (!(item.isWanCheng && !item.isTongGuo)) {
              IsKaoShi = false;
            }
            return item;
          }
        });
        if (IsKaoShi) {
          showModel({
            content: "恭喜您已经完成所有课程学习，请前往考试吧",
            showClose: true,
            showCancelButton: true,
            confirmButtonText: "前往考试",
            closeModal: false,
            confirm: () => {
              console.log("前往考试");
              this.keepAliveNavigation(
                "examination?courseId=" + that.playerList[0].studyCourseId
              );
            },
          });
        } else {
          showModel({
            content: `本课程共有${res.data.couresContentList.length}个章节，须完成所有章节方可参加考试`,
            showClose: true,
            confirmButtonText: "确定",
            closeModal: true,
            confirm: () => {},
          });
        }
        // 处理附件下载
        if (res.data && res.data.PDFurl) {
          if (JSON.parse(res.data.PDFurl).length > 0) {
            that.courseInfo.PDFurl = JSON.parse(res.data.PDFurl);
          } else {
            that.courseInfo.PDFurl = "";
          }
        } else {
          that.courseInfo.PDFurl = "";
        }
        // 完成进度处理
        this.finished = true;
      });
    },
    // 点击切换视频
    playItem(item, index) {
      console.log(item, index);
      if (this.TimeUpDate) {
        clearInterval(this.TimeUpDate);
        this.TimeUpDate = null;
      }
      this.CurrentTime = 0;
      this.forTheFirstTime = true;
      this.playerIndex = index;
      this.playerEndIndex = JSON.parse(JSON.stringify(this.playerIndex));
      this.$refs.videoPlayer.playerSwitch(this.playerIndex);
      this.$refs.videoPlayer.playerEl.play();
    },
    // 自动切换视频
    playerSwitch(data) {
      if (this.TimeUpDate) {
        clearInterval(this.TimeUpDate);
        this.TimeUpDate = null;
      }
      this.CurrentTime = 0;
      this.forTheFirstTime = true;
      this.playerIndex = data.index;
    },
    // 进度条变化
    videoPlayerTimeUpDate(args) {
      if (args.currentTime()) {
        let num = Math.floor((args.currentTime() / args.duration()) * 100);
        if (num >= 100) {
          num = 99;
        }
        this.$set(this.playerList[this.playerIndex], "isWanChengRatio", num);
      }
      this.CurrentTime = args.currentTime();
    },
    // 上传进度变化
    uploadDateTime() {
      this.TimeUpDate = setInterval(() => {
        // 每两分钟 上传一次视频进度
        getStudyProcessUpdate({
          c3R1ZHlQcm9jZXNzSWQ: this.studyProcessId,
          cgxhewluz1rpbwu: Number(this.CurrentTime.toFixed(0)),
          trainingId: this.yearId,
        }).then(() => {
          console.log("上传进度");
        });
      }, 120000);
    },
  },
};
</script>
<style scoped lang="scss">
.expandicon {
  transform: rotate(180deg);
}

.m_myCourse {
  height: 100%;
  width: 100%;
}

/deep/ .m-body {
  padding: 0px !important;
}

.player {
  width: 100%;
  height: 210px;
}

.courseInfo {
  background: #ffffff;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;

  .title {
    font-size: 16px;
    font-weight: 500;

    .expand-collapse {
      margin-left: 10px;
      font-size: 12px !important;
      color: #818496;
    }
  }

  .name-time {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin: 5px auto 10px auto;
    color: #818496;
    font-size: 12px;

    .name {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
    }

    .time {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .file-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    border-radius: 4px;
    color: #ba2926;
    font-size: 12px;
    background: rgba(186, 41, 38, 0.1);
    padding: 6px 8px;
    box-sizing: border-box;
    border: 1px solid #ba2926;
  }

  .comment {
    .satisfaction {
      color: #505363;
    }

    .satisfactionValue {
      margin: 10px 0px;
      color: #818496;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
    }

    .commontInput {
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: stretch;

      .input {
        width: 100%;
        padding: 10px 16px;
        border: none;
        background: #f1f2f3;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        white-space: nowrap;
        color: #818496;
        font-size: 14px;
      }

      .button {
        width: max-content;
        padding: 10px;
        font-size: 14px;
        color: #ffffff;
        background: #ba2926;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        white-space: nowrap;
      }
    }
  }
}

.studyProgress {
  background: #ffffff;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  margin: 12px auto;

  .top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    .left {
      font-size: 16px;
      color: #505363;
    }

    .right {
      font-size: 16px;
      font-weight: 500;

      span {
        color: #ba2926;
        font-size: 16px;
      }
    }
  }

  .bottom {
    margin-top: 5px;
  }
}

.courseCatalogue {
  background: #ffffff;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  margin: 12px auto;

  .title {
    color: #21252e;
    font-size: 16px;
    font-weight: 500;

    .value {
      font-size: 12px;
      color: #818496;
    }
  }

  .item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    background: rgba(204, 204, 204, 0.12);
    border-radius: 4px;
    margin: 15px auto;

    .left {
      text-align: left;

      .top {
        display: flex;

        .index {
          color: #1677fe;
          font-size: 12px;
        }
      }

      .titles {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: #1677fe;
        font-size: 14px;
        font-weight: 500;
        margin-top: 5px;
      }
    }

    .right {
      width: 60px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      min-width: 60px;
    }
  }

  .itemPlayer {
    background: rgba(22, 119, 254, 0.06);
  }
}
</style>
